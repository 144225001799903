body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  background-color: black;
}

p {
  text-shadow: 2px 2px 8px black;
}

.p1 {
  background-color: transparent;
  color: white;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: bold;
  font-size: 5vw;
  width: 60vw;
  height: 20px;
  position:absolute;
  top:0;
  right:0;
  z-index: 90;
}

.p2 {
  background-color: transparent;
  color: white;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: bold;
  font-size: 5vw;
  width: 60vw;
  height: 20px;
  position:absolute;
  top:0;
  left:0;
  z-index: 90;
}

.p3 {
  background-color: transparent;
  color: white;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: bold;
  font-size: 5vw;
  width: 60vw;
  height: 20px;
  position:absolute;
  bottom:10;
  right:10;
  z-index: 90;
}

.p4 {
  background-color: transparent;
  color: white;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: bold;
  font-size: 5vw;
  width: 60vw;
  height: 20px;
  position:absolute;
  padding:5px;
  bottom:10;
  left:10;
  z-index: 90;
}

button {
  text-shadow: 1px 1px 3px black;
  color:white;
  border-color: white;
  background-color: transparent;
  font-size: large;
  position: fixed;
  width: 150px;
  height: 40px;
  top: 70%;
  left: 50%;
  margin-top: -20px; /* Negative half of height. */
  margin-left: -75px; /* Negative half of width. */
  z-index: 100;
}

.shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.3s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}